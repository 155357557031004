body {
  margin: 0;
  padding: 0;

  background-color: #fff;
}

*, *:before, *:after {
  box-sizing: border-box;
}

/* react-select */
.css-1rhbuit-multiValue {
  z-index: 1202;
}

/* react-draft-wysiwyg */
.public-DraftEditor-content {
  border: 1px solid #F1F1F1;

  padding: 18.5px 14px;
}

.se-wrapper-inner.se-wrapper-wysiwyg.sun-editor-editable {
  padding: 16px;
}

.sun-editor-editable {
  font-family: "Roboto", 'sans-serif' !important;

  padding: 0;

  font-family: "Roboto", sans-serif;

  font-size: 16px;

  line-height: 27px;
  letter-spacing: 0.02em;

  break-inside: avoid;

  @media (max-width: 599.95px) {
    font-size: 14px;

    line-height: 169%;
  }

  * {
    font-family: "Roboto", 'sans-serif' !important;

    margin: 0;
    padding: 0;

    color: #000000;
  }

  & > * {
    margin-bottom: 33px;

    max-width: 100%;
  }

  h4 {
    margin-bottom: 9px;

    font-family: "Roboto", sans-serif;
    font-weight: 500;

    font-size: 18px;

    letter-spacing: 0.02em;
    text-transform: uppercase;

    @media (max-width: 599.95px) {
        font-size: 16px;
    }
  }

  p {
    font-size: 16px;

    line-height: 27px;
    letter-spacing: 0.02em;

    break-inside: avoid;

    margin-bottom: 25px;

    @media (max-width: 599.95px) {
      font-size: 14px;

      line-height: 169%;
    }
  }


  ol,
  ul {
    margin-left: 63px;
    margin-bottom: 33px;

    list-style: none;

    @media (max-width: 1279.95px) {
      margin-left: 20px;
    }
  }

  li {
    position: relative;

    margin-bottom: 12px;
    padding-left: 43px;

    font-family: "Roboto", sans-serif;
    font-size: 16px;

    & p {
      margin-bottom: 5px;
    }
    
    @media (max-width: 599.95px) {
      font-size: 14px;
    }
  }

  ol {
    counter-reset: ol-counter;
  }

  ol li {
    counter-increment: ol-counter;
  }

  ol li::after {
    content: counter(ol-counter) ".";

    position: absolute;
    top: 0;
    left: 0;

    font-size: 16px;
    font-family: "Roboto", sans-serif;
  }

  ul li::after {
    content: "";

    position: absolute;
    top: 3px;
    left: 0;

    width: 17px;
    height: 17px;

    border-radius: 50%;
    background-color: #1499fe;
  }

  img, .se-image-container {
    max-width: 100%;
  }

  iframe {
    width: 100%;
    height: 411px;

    @media (max-width: 599.95px) {
      height: 250px;
    }
    
  }

  a {
    color:  #1499FE;

    &:hover {
      color: #e91e63;
    }
  }

  @media (max-width: 960px) {
    .__se__float-left, .__se__float-right {
      float: none;
    }

    .se-image-container, img, figure {
      width: auto !important;
    }
  }
}

a {
  color: inherit;
}